import React, { useState } from "react";
import styles from "./index.module.css";
import { PasswordIcon, RightArrow, UserIcon } from "../../icons";
import auth from "../../api/auth";
import ReCAPTCHA from "react-google-recaptcha"; // Import ReCAPTCHA

const Home = () => {
  const [credentials, setCredentials] = useState({
    username: null,
    password: null,
  });
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const login = (e) => {
    e.preventDefault();
    setError(true);

    if (
      credentials.password === null ||
      credentials.username === null ||
      recaptchaToken === null
    ) {
      setError("required");
      setLoad(false);
    } else {
      auth
        .login({
          username: credentials.username,
          password: credentials.password,
          'g-recaptcha-response': recaptchaToken,
        })
        .then((response) => {
          console.log(response);
          sessionStorage.setItem("token", response.data.data.token);
          sessionStorage.setItem("name", response.data.data.user.name);
          window.location.replace("/registered");
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
          setError("wrongCredentials");
        });
    }
  };

  const handleInput = (e) => {
    setError(null);
    setCredentials((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token); // Set the recaptcha token when it is successfully validated
  };
  return (
    <section className={styles.section}>
      <div className={styles.login}>
        <h2>Giriş</h2>
        <form onSubmit={(e) => login(e)}>
          <label htmlFor="username">
            <UserIcon /> İstifadəçi adı
          </label>
          <input
            style={
              error === "required"
                ? { borderColor: "red", outline: "red" }
                : null
            }
            type="text"
            onChange={(e) => handleInput(e)}
            name="username"
          />
          <label htmlFor="password">
            <PasswordIcon /> Şifrə
          </label>
          <input
            type="password"
            onChange={(e) => handleInput(e)}
            name="password"
            style={
              error === "required"
                ? { borderColor: "red", outline: "red" }
                : null
            }
          />
          {error === "wrongCredentials" ? (
            <p className={styles.error}>Şifrə və ya istifadəçi adı yanlısdir</p>
          ) : null}

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleRecaptcha}
            style={{ margin: "auto" }}
          />
          <button
            onClick={() => {
              setLoad(true);
              sessionStorage.removeItem("token");
            }}
            style={{ marginTop: "20px" }}
          >
            Daxil ol <RightArrow />
          </button>
          {load ? <div className={styles.overlay}></div> : null}
        </form>
      </div>
    </section>
  );
};

export default Home;
