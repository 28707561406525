import React from "react";
import styles from "./index.module.css";
import { LockIcon } from "../../../icons";
import auth from "../../../api/auth";

const Header = () => {
  const handleLogout = () => {
    async function logoff() {
      const data_ = await auth
        .logout()
        .then((response) => {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("name");
          window.location.replace("/");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    logoff();
  };
  return (
    <header className={styles.header}>
      <h2>{sessionStorage.getItem("name")}</h2>
      <button onClick={handleLogout}>
        <LockIcon />
        Çıxış et
      </button>
    </header>
  );
};

export default Header;
