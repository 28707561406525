const UserIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        stroke="#7D7D7D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26003 15 3.41003 18.13 3.41003 22"
        stroke="#7D7D7D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const PasswordIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
        stroke="#7D7D7D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z"
        stroke="#7D7D7D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9965 16H16.0054"
        stroke="#7D7D7D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9955 16H12.0045"
        stroke="#7D7D7D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99451 16H8.00349"
        stroke="#7D7D7D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const RightArrow = () => {
  return (
    <svg
      width="30"
      height="14"
      viewBox="0 0 30 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9299 1L28.9999 7.07L22.9299 13.14"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 7L29 7"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const UploadIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 73.3333H50C66.6667 73.3333 73.3334 66.6666 73.3334 50V30C73.3334 13.3333 66.6667 6.66663 50 6.66663H30C13.3334 6.66663 6.66669 13.3333 6.66669 30V50C6.66669 66.6666 13.3334 73.3333 30 73.3333Z"
        stroke="#141522"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 31.7001L40 21.7001L50 31.7001"
        stroke="#141522"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40 21.7001V48.3667"
        stroke="#141522"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 55.0333C32.9667 59.3667 47.0333 59.3667 60 55.0333"
        stroke="#141522"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const LockIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11"
        stroke="#5D5D5D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9999 14.6C12.3312 14.6 12.5999 14.3314 12.5999 14C12.5999 13.6687 12.3312 13.4 11.9999 13.4C11.6685 13.4 11.3999 13.6687 11.3999 14C11.3999 14.3314 11.6685 14.6 11.9999 14.6Z"
        stroke="#5D5D5D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z"
        stroke="#5D5D5D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#5D5D5D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const AddIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12H16"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 16V8"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const Dots = () => {
  return (
    <svg
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29H15C20 29 22 27 22 22V16C22 11 20 9 15 9H9C4 9 2 11 2 16V22C2 27 4 29 9 29Z"
        stroke="#141522"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9965 19H16.0054"
        stroke="#141522"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9955 19H12.0045"
        stroke="#141522"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99451 19H8.00349"
        stroke="#141522"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const TrashIcon = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const FramedTrashIcon = () => {
  return (
    <svg
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29H15C20 29 22 27 22 22V16C22 11 20 9 15 9H9C4 9 2 11 2 16V22C2 27 4 29 9 29Z"
        stroke="#141522"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 14.5L15.8993 23.5097C15.8468 24.2987 15.8204 24.6933 15.65 24.9925C15.4999 25.2559 15.2736 25.4676 15.0008 25.5999C14.691 25.75 14.2955 25.75 13.5046 25.75H10.4953C9.70445 25.75 9.30901 25.75 8.99917 25.5999C8.72638 25.4676 8.50004 25.2559 8.34999 24.9925C8.17955 24.6933 8.15325 24.2987 8.10064 23.5097L7.5 14.5M6 14.5H18M15 14.5L14.797 13.8911C14.6003 13.3009 14.5019 13.0059 14.3195 12.7877C14.1584 12.5951 13.9516 12.446 13.7179 12.3541C13.4532 12.25 13.1422 12.25 12.5202 12.25H11.4798C10.8578 12.25 10.5468 12.25 10.2821 12.3541C10.0484 12.446 9.84156 12.5951 9.68047 12.7877C9.49804 13.0059 9.39969 13.3009 9.20298 13.8911L9 14.5"
        stroke="#141522"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const SearchIcon = () => {
  return (
    <svg
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29H15C20 29 22 27 22 22V16C22 11 20 9 15 9H9C4 9 2 11 2 16V22C2 27 4 29 9 29Z"
        stroke="#141522"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_9_7)">
        <path
          d="M6 18.4098C6 21.3942 8.42752 23.8196 11.4087 23.8196C12.7821 23.8196 14.0385 23.3031 14.9941 22.4565L18.441 25.9042C18.5049 25.9681 18.5874 26 18.6726 26C18.7578 26 18.8403 25.9681 18.9042 25.9042C19.0319 25.7764 19.0319 25.5714 18.9042 25.4436L15.4545 21.9959C16.301 21.0401 16.8174 19.7862 16.8174 18.4098C16.8174 15.4254 14.3898 13 11.4087 13C8.42752 13 6 15.4254 6 18.4098ZM16.1652 18.4098C16.1652 21.0322 14.0305 23.1673 11.4087 23.1673C8.78685 23.1673 6.65213 21.0322 6.65213 18.4098C6.65213 15.7874 8.78685 13.6523 11.4087 13.6523C14.0305 13.6523 16.1652 15.7848 16.1652 18.4098Z"
          fill="#141522"
        />
      </g>
      <defs>
        <clipPath id="clip0_9_7">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const RefreshIcon = () => {
  return (
    <svg
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 29H15C20 29 22 27 22 22V16C22 11 20 9 15 9H9C4 9 2 11 2 16V22C2 27 4 29 9 29Z"
        stroke="#141522"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_9_7)">
        <path
          d="M14.2904 14.028C13.4732 13.6925 12.597 13.5247 11.7205 13.5247C10.9971 13.5247 10.2756 13.6449 9.58499 13.8736L8.87089 13.1461C8.64113 12.9122 8.36853 12.9648 8.26211 13.2638L7.03009 16.711C6.92339 17.0093 7.10949 17.1986 7.44567 17.1342L11.3281 16.386C11.6643 16.3209 11.7511 16.0788 11.5213 15.8456L10.9802 15.2939C11.8343 15.1612 12.7208 15.254 13.5261 15.5845C14.6007 16.0256 15.4228 16.822 15.8414 17.8285C16.2603 18.8344 16.2248 19.9292 15.7426 20.9111C15.2605 21.8932 14.3886 22.6447 13.2876 23.0272C12.1871 23.4093 10.9889 23.3776 9.91462 22.9374C9.91462 22.9374 9.74353 23.2856 9.53233 23.7158C9.32113 24.145 9.65785 24.7149 10.3275 24.8558C11.5254 25.1072 12.7773 25.0301 13.9515 24.6224C15.5186 24.0767 16.7596 23.0068 17.4462 21.6097C18.1327 20.212 18.1827 18.6533 17.5864 17.2212C16.9905 15.7895 15.8199 14.6552 14.2904 14.028Z"
          fill="#141522"
        />
      </g>
      <defs>
        <clipPath id="clip0_9_7">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export {
  UserIcon,
  PasswordIcon,
  RightArrow,
  UploadIcon,
  LockIcon,
  AddIcon,
  Dots,
  TrashIcon,
  FramedTrashIcon,
  SearchIcon,
  RefreshIcon,
};
