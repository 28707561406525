import Home from "./pages/home";
import NotFound from "./pages/notFound";
import Registartion from "./pages/registration";
import Registered from "./pages/registered";
import Show from "./pages/show/[id]";
const routeItem = (id, title, path, component) => {
  return {
    id,
    title,
    path,
    component,
  };
};

const routes = {
  home: routeItem(1, 'home', "/", Home),
  registration: routeItem(2, 'registration', "/registration", Registartion),
  registered: routeItem(3, 'registered', "/registered", Registered ),
  show: routeItem(4, 'show', "/show/:id", Show ),
  notFound: routeItem(99, 'notFound', "*", NotFound),
};

const routeArr = Object.values(routes);

export { routes, routeArr };
