import axios from "../index";

const login = data => {
  return axios.post('login', data);
}
const logout = () => {
  return axios.get('logout',{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
}
const getSessionUser = () => {
  return axios.get('/sessionUser',{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
}
const getRegister = (query,filterData) => {
  return axios.get(`enterprise/get?page=${query}&name=${filterData.name}&subordination_id=${filterData.subordination_id}&category_id=${filterData.category_id}&district_id=${filterData.district_id}&activity=${filterData.activity}`,{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
}
const showInfo = query => {
  return axios.get(`/enterprise/show/${query}`,{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
}
export default { login ,logout,getSessionUser,getRegister,showInfo}