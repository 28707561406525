import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import {
  AddIcon,
  Dots,
  FramedTrashIcon,
  RefreshIcon,
  SearchIcon,
  TrashIcon,
} from "../../icons";
import { Pagination } from "@mui/material";
import "./pagination.css";
import auth from "../../api/auth";
import form from "../../api/form";
import dropdowns from "../../api/dropdowns";
import { useForm } from "react-hook-form";
const Registered = () => {
  const handleChangeQuerry = (e) => {
    if (e.target.dataset.testid == "NavigateBeforeIcon") {
      setQuery(query - 1);
    } else if (e.target.dataset.testid == "NavigateNextIcon") {
      setQuery(query + 1);
    } else {
      setQuery(Number(e.target.textContent));
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [query, setQuery] = useState(1);
  const [data, setData] = useState(null);
  const [subordination, setSubordination] = useState(null);
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [load, setLoad] = useState(false);
  const [districts, setDistricts] = useState(null);
  const [category, setCategory] = useState(null);
  const [filterData, setFilterData] = useState({
    name: "",
    subordination_id: "",
    category_id: "",
    district_id: "",
    activity: "",
  });

  const handleDeleteRegistration = () => {
    setLoad(true);
    async function deletePost() {
      const data_ = await form
        .deleteRegister(deleteId)
        .then((response) => {
          setLoad(false);
          console.log(response);
          window.location.reload();
        })
        .catch((error) => {
          setLoad(false);
          console.log(error);
          setModal(false);
        });
    }
    deletePost();
  };
  const handleChange = (key, value) => {
    setFilterData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const handleSearch = () => {
    setLoad(true);
    async function fetchData() {
      const { data } = await auth.getRegister(query, filterData);
      setData(data);
      setLoad(false);
    }
    fetchData();
  };
  useEffect(() => {
    async function fetchData() {
      const { data } = await auth.getRegister(query, filterData);
      setData(data);
    }
    async function getSubordination() {
      const { data } = await dropdowns.subordination();
      setSubordination(data);
    }
    async function fetchDistrics() {
      const { data } = await dropdowns.districts("");
      setDistricts(data);
    }
    async function fetchCategory() {
      const { data } = await dropdowns.category();
      setCategory(data);
    }
    if (sessionStorage?.getItem("token") !== null) {
      getSubordination();
      fetchDistrics();
      fetchCategory();
      fetchData();
    } else {
      window.location.replace("/");
    }
  }, [query]);
  console.log(filterData);
  return (
    <>
      {sessionStorage.getItem("token") ? (
        <>
          <Header />
          {modal ? (
            <div className={styles.modal}>
              <div>
                <h2>Qeydiyyatı silməyinizə əminsiz?</h2>
                <div>
                  <button
                    className={styles.greenButton}
                    onClick={() => setModal(false)}
                  >
                    Yox
                  </button>
                  <button
                    className={styles.redButton}
                    onClick={() => handleDeleteRegistration()}
                  >
                    Bəli
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {load ? <div className={styles.overlay}></div> : null}
          <section>
            <div className={styles.wrapper}>
              <div className={styles.add}>
                <Link to={"/registration"}>
                  <AddIcon /> Əlavə et
                </Link>
              </div>
              <h2 className={styles.filterText}>Filter</h2>
              <div className={styles.filter}>
                <div>
                  <input
                    type="text"
                    placeholder="Müəssisə adı"
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </div>
                <div>
                  <select
                    name="subordination"
                    id="subordination"
                    onChange={(e) =>
                      handleChange("subordination_id", e.target.value)
                    }
                  >
                    <option value="">Tabeçilik</option>
                    {subordination?.data?.subordinations?.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="region"
                    id="region"
                    onChange={(e) =>
                      handleChange("district_id", e.target.value)
                    }
                  >
                    <option value="">Rayon</option>
                    {districts?.data?.districts?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="category"
                    id="category"
                    onChange={(e) =>
                      handleChange("category_id", e.target.value)
                    }
                  >
                    <option value="">Kateqoriya</option>
                    {category?.data?.categories?.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="field"
                    id="field"
                    onChange={(e) => handleChange("activity", e.target.value)}
                  >
                    <option value="">Fəaliyyət</option>
                    <option value="1">Fəaliyyət göstərir</option>
                    <option value="2">Fəaliyyət göstərmir</option>
                    <option value="3">
                      Noyabr 2024-də fəaliyyətə hazır olacaq
                    </option>
                  </select>
                </div>
                <div className={styles.search}>
                  <button
                    className={styles.grayButton}
                    onClick={() => window.location.reload()}
                  >
                    Təmizlə
                  </button>
                  <button className={styles.greenButton} onClick={handleSearch}>
                    Axtar
                  </button>
                </div>
              </div>
              <table>
                <tr>
                  <th>№</th>
                  <th>Müəssisə adı</th>
                  <th>Tabeçilik</th>
                  <th>Region</th>
                  <th>Rayon</th>
                  <th>Kateqoriya</th>
                  <th>Fəaliyyət</th>
                  <th>Yaradıcı</th>
                  <th>Yaranma tarixi</th>
                  <th></th>
                </tr>
                <div className={styles.line}></div>
                {data?.data?.enterprises?.map((item, i) => (
                  <tr className={styles.tr}>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.subordination_label}</td>
                    <td>{item.region_label}</td>
                    <td>{item.district_label}</td>
                    <td>{item.category_label}</td>
                    <td>{item.activity_label}</td>
                    <td>{item.created_by_label}</td>

                    <td>{item.created_at}</td>
                    <td className={styles.dots}>
                      <div
                        className={styles.deleteIcon}
                        onClick={() => {
                          setDeleteId(item.id);
                          setModal(true);
                        }}
                      >
                        <FramedTrashIcon />
                      </div>
                      <Link to={"/show/" + item.id}>
                        <Dots />
                      </Link>
                    </td>
                  </tr>
                ))}
              </table>
              <div className={styles.pagination}>
                <Pagination
                  count={Math.ceil(data?.meta?.total / 10)}
                  variant="outlined"
                  shape="rounded"
                  onChange={(e) => handleChangeQuerry(e)}
                />
              </div>
            </div>
            <div className={styles.wrapperM}>
              <div className={styles.add}>
                <Link to={"/registration"}>
                  <AddIcon /> <p>Əlavə et</p>
                </Link>
              </div>
              <h2 className={styles.filterText}>Filter</h2>
              <div className={styles.filter}>
                <div>
                  <input
                    type="text"
                    placeholder="Müəssisə adı"
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </div>
                <div>
                  <select
                    name="subordination"
                    id="subordination"
                    onChange={(e) =>
                      handleChange("subordination_id", e.target.value)
                    }
                  >
                    <option value="">Tabeçilik</option>
                    {subordination?.data?.subordinations?.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="region"
                    id="region"
                    onChange={(e) =>
                      handleChange("district_id", e.target.value)
                    }
                  >
                    <option value="">Rayon</option>
                    {districts?.data?.districts?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="category"
                    id="category"
                    onChange={(e) =>
                      handleChange("category_id", e.target.value)
                    }
                  >
                    <option value="">Kateqoriya</option>
                    {category?.data?.categories?.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="field"
                    id="field"
                    onChange={(e) => handleChange("activity", e.target.value)}
                  >
                    <option value="">Fəaliyyət</option>
                    <option value="1">Fəaliyyət göstərir</option>
                    <option value="2">Fəaliyyət göstərmir</option>
                    <option value="3">
                      Noyabr 2024-də fəaliyyətə hazır olacaq
                    </option>
                  </select>
                </div>
                <div className={styles.search}>
                  <button
                    className={styles.grayButton}
                    onClick={() => window.location.reload()}
                  >
                    Təmizlə
                  </button>
                  <button className={styles.greenButton} onClick={handleSearch}>
                    Axtar
                  </button>
                </div>
              </div>
              <table>
                <tr>
                  <th style={{ width: "10%" }}>№</th>
                  <th>Müəssisə adı</th>
                  <th>Kateqoriya</th>
                  <th></th>
                </tr>
                <div className={styles.line}></div>

                {data?.data?.enterprises?.map((item, i) => (
                  <tr className={styles.tr}>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.category_label}</td>
                    <td className={styles.dots}>
                      <div
                        className={styles.deleteIcon}
                        onClick={() => {
                          setDeleteId(item.id);
                          setModal(true);
                        }}
                      >
                        <FramedTrashIcon />
                      </div>
                      <Link to={"/show/" + item.id}>
                        <Dots />
                      </Link>
                    </td>
                  </tr>
                ))}
              </table>
              <div className={styles.pagination}>
                <Pagination
                  count={Math.ceil(data?.meta?.total / 10)}
                  variant="outlined"
                  shape="rounded"
                  onChange={(e) => handleChangeQuerry(e)}
                />
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default Registered;
