import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { RightArrow, UploadIcon } from "../../icons";
import dropdowns from "../../api/dropdowns";
import { set, useForm } from "react-hook-form";
import form from "../../api/form";
import Select from "react-select";
import Spinner from "../../assets/images/Spinner-1s-200px.svg";

const Registartion = () => {
  const [load, setLoad] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [environmentalSustainability, setEnvironmentalSustainability] =
    useState([]);
  const [category, setCategory] = useState([]);
  const [subordination, setSubordination] = useState([]);
  const [data, setData] = useState(null);
  const [frontImageError, setFrontImageError] = useState(null);
  const [frontImageLink, setFrontImageLink] = useState(null);
  const [frontImage, setFrontImage] = useState(null);

  const [roomsImageError, setRoomsImageError] = useState(null);
  const [roomsImageLink, setRoomsImageLink] = useState(null);
  const [roomsImage, setRoomsImage] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [regionSelectQuerry, setRegionSelectQuerry] = useState(1);
  const [validation, setValidation] = useState(false);
  const [parkingDis, setParkingDis] = useState(true);
  const [confranceDis, setConfranceDis] = useState(true);
  const [restourantDis, setRestourantDis] = useState(true);
  const [poolDis, setPoolDis] = useState(true);
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  const [phonePrefix, setPhonePrefix] = useState("050");

  const handlePhone = (e) => {
    const inputValue = e.target.value;
    setPhoneError(false);
    const regex = /^[0-9]*$/;
    if (inputValue.length <= 7 && regex.test(inputValue)) {
      setPhone(inputValue);
    } else {
      if (inputValue.length > 7) {
        setPhone(inputValue.slice(0, 7));
      } else {
        setPhone(inputValue);
      }
    }
  };
  const handlePhonePrefix = (e) => {
    setPhonePrefix(e.target.value);
  };
  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };
  const handleFrontImage = (e) => {
    setFrontImageError(null);
    setFrontImageLink(URL.createObjectURL(e.target.files[0]));
    setFrontImage(e.target.files[0]);
  };
  const handleRoomImages = (e) => {
    const addRoomsImageLink = (newImages) => {
      if (roomsImageLink !== null) {
        setRoomsImageLink([...roomsImageLink, ...newImages]);
      } else {
        setRoomsImageLink([...newImages]);
      }
    };
    const addRoomsImage = (newImages) => {
      if (roomsImage !== null) {
        setRoomsImage([...roomsImage, ...newImages]);
      } else {
        setRoomsImage([...newImages]);
      }
    };
    const files = e.target.files;
    const imagesArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setRoomsImageError(null);
    addRoomsImageLink(imagesArray);
    addRoomsImage(e.target.files);
  };
  const options = environmentalSustainability.map((option) => ({
    label: option.name,
    value: option.id,
  }));
  const handleDelete = (e, i) => {
    e.preventDefault();
    setRoomsImageLink((files) => {
      const updatedFiles = [...files];
      updatedFiles.splice(i, 1);
      return updatedFiles;
    });
    setRoomsImage((files) => {
      const updatedFiles = [...files];
      updatedFiles.splice(i, 1);
      return updatedFiles;
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    async function fetchDistrics() {
      const { data } = await dropdowns.districts(regionSelectQuerry);
      setDistricts(data);
    }
    async function fetchEnvironmentalSustainability() {
      const { data } = await dropdowns.environmentalSustainability();
      setEnvironmentalSustainability(data.data.environmental_sustainabilities);
    }
    async function fetchCategory() {
      const { data } = await dropdowns.category();
      setCategory(data);
    }
    async function fetchSubordination() {
      const { data } = await dropdowns.subordination();
      setSubordination(data);
    }
    if (sessionStorage?.getItem("token") !== null) {
      fetchDistrics();
      fetchEnvironmentalSustainability();
      fetchCategory();
      fetchSubordination();
    } else {
      window.location.replace("/");
    }
  }, [regionSelectQuerry]);
  return (
    <section className={styles.section}>
      {load ? (
        <div className={styles.overlay}>
          <img src={Spinner} alt="" />
        </div>
      ) : null}

      {sessionStorage.getItem("token") ? (
        <>
          <div className={styles.registration}>
            <h2 className={styles.h2First}>Əlavə etmə</h2>
            <hr />
            <form
              onSubmit={handleSubmit((data, e) => {
                e.preventDefault();
                setValidation(false);
                setLoad(true);
                async function registerPost() {
                  const datah = {
                    ...data,
                    front_photo: frontImage,
                    room_photos: roomsImage,
                    environmental_sustainabilities: selectedOptions?.map(
                      (option) => option.value
                    ),
                    phone: phonePrefix + "" + phone,
                  };
                  const data_ = await form
                    .register(datah)
                    .then((response) => {
                      setLoad(false);
                      window.location.replace("/registered");
                    })
                    .catch((error) => {
                      const array = Object.keys(error.response.data.data).map(
                        (key) => error.response.data.data[key]
                      );
                      setValidation(array);
                      window.scrollTo({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: "smooth",
                      });
                      setLoad(false);
                    });
                }
                if (phone === "") {
                  console.log("sds");
                  setPhoneError(true);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }
                if (frontImage === null || roomsImage === null) {
                  setFrontImageError(true);
                  setRoomsImageError(true);
                  setLoad(false);
                } else {
                  registerPost();
                }
              })}
            >
              <div className={styles.wrapper}>
                <h2>Ərazi</h2>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="region">
                      Region <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.region
                          ? {
                              borderColor: "red",
                              outline: "red",
                            }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="baki"
                          name="region"
                          value="1"
                          {...register("region", { required: true })}
                          onClick={() => setRegionSelectQuerry(1)}
                        />
                        <label
                          for="baki"
                          className={styles.p}
                          onClick={() => setRegionSelectQuerry(1)}
                        >
                          Bakı
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="rayon"
                          name="region"
                          value="0"
                          {...register("region")}
                          onClick={() => setRegionSelectQuerry(2)}
                        />
                        <label
                          for="rayon"
                          className={styles.p}
                          onClick={() => setRegionSelectQuerry(2)}
                        >
                          Region
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="region">Rayon</label>
                    <select
                      name="region"
                      id="region"
                      {...register("district_id", { value: "1" })}
                    >
                      {districts?.data?.districts?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.selectRes}>
                    <label htmlFor="address">
                      Ünvan <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      {...register("address", { required: true })}
                      style={
                        errors.address
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      className={styles.inputRes}
                    />
                    {errors.address && (
                      <p className={styles.textDanger}>Ünvanı daxil edin</p>
                    )}
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="cordX">
                      Koordinat X <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="0.000000000001"
                      {...register("coordinate_x", { required: true })}
                      style={
                        errors.coordinate_x
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.coordinate_x && (
                      <p className={styles.textDanger}>
                        X koordinatını daxil edin
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="cordY">
                      Koordinat Y <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="0.000000000001"
                      {...register("coordinate_y", { required: true })}
                      style={
                        errors.coordinate_y
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.coordinate_y && (
                      <p className={styles.textDanger}>
                        Y koordinatını daxil edin
                      </p>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Kateqoriyalar</h2>
                <div className={styles.secondRow}>
                  <div className={styles.region1} style={{ width: 29 + "%" }}>
                    <label htmlFor="category">Kateqoriya</label>
                    <select
                      name="category"
                      id="category"
                      {...register("category_id", { value: "1" })}
                    >
                      {category?.data?.categories?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="star">Ulduz</label>
                    <select
                      name="star"
                      id="star"
                      {...register("star", { value: "0" })}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  {/*  */}
                  <div>
                    <label htmlFor="structureName">
                      Müəssisənin adı <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      {...register("name", { required: true })}
                      style={
                        errors.name
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.name && (
                      <p className={styles.textDanger}>
                        Müəssisənin adını daxil edin
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="name_surname">
                      Ad, soyad <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      {...register("name_surname", { required: true })}
                      style={
                        errors.name_surname
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.name_surname && (
                      <p className={styles.textDanger}>Ad, soyadı daxil edin</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="phone">
                      Nömrə <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <div className={styles.numberInput}>
                      <select
                        name="prefix"
                        id="prefix"
                        value={phonePrefix}
                        onChange={(e) => handlePhonePrefix(e)}
                      >
                        <option value="050">050</option>
                        <option value="051">051</option>
                        <option value="010">010</option>
                        <option value="070">070</option>
                        <option value="077">077</option>
                        <option value="055">055</option>
                        <option value="090">090</option>
                        <option value="012">012</option>
                      </select>
                      <input
                        type="number"
                        step="1"
                        value={phone}
                        onChange={(e) => handlePhone(e)}
                        style={
                          phoneError
                            ? { borderColor: "red", outline: "red" }
                            : null
                        }
                      />
                    </div>
                    {phoneError && (
                      <p className={styles.textDanger}>Nömrəni daxil edin</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="subordination">Tabeçilik</label>
                    <select
                      name="subordination"
                      id="subordination"
                      className={styles.tabe}
                      {...register("subordination_id", { value: "1" })}
                    >
                      {subordination?.data?.subordinations?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Müəssisənin sahə tutumu</h2>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="area">Ümumi sahə (m&sup2;)</label>
                    <input
                      type="text"
                      {...register("common_area")}
                      style={
                        errors.common_area
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.common_area && (
                      <p className={styles.textDanger}>
                        Ümumi sahəni daxil edin
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="buildingCount">
                      Bina sayı <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="1"
                      {...register("building_count", { required: true })}
                      className={styles.inputRes}
                      style={
                        errors.building_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.building_count && (
                      <p className={styles.textDanger}>
                        Bina sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="suitCount">
                      Otaq sayı <span className={styles.redStar}>*</span>
                    </label>
                    <input
                      className={styles.inputRes}
                      type="number"
                      step="1"
                      {...register("room_count", { required: true })}
                      style={
                        errors.room_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.room_count && (
                      <p className={styles.textDanger}>
                        Otaq sayını daxil edin
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.fiveIn}>
                  <div>
                    <label htmlFor="oneFloor">1 otaqlı</label>
                    <input type="number" step="1" {...register("one")} />
                  </div>
                  <div>
                    <label htmlFor="twoFloor">2 otaqlı</label>
                    <input type="number" step="1" {...register("two")} />
                  </div>
                  <div>
                    <label htmlFor="threeFloor">3 otaqlı</label>
                    <input type="number" step="1" {...register("three")} />
                  </div>
                  <div>
                    <label htmlFor="fourFloor">4 otaqlı</label>
                    <input type="number" step="1" {...register("four")} />
                  </div>
                  <div>
                    <label htmlFor="fiveFloor">5 otaqlı</label>
                    <input type="number" step="1" {...register("five")} />
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="suitCount">Suit sayı</label>
                    <input type="number" step="1" {...register("suit")} />
                  </div>
                  <div>
                    <label htmlFor="presidentSuitCount">
                      Prezident suit sayı
                    </label>
                    <input
                      type="number"
                      step="1"
                      {...register("president_suit")}
                    />
                  </div>
                  <div>
                    <label htmlFor="address">Kotec sayı</label>
                    <input type="number" step="1" {...register("kotec")} />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div className={styles.region1}>
                    <label htmlFor="floor">Mərtəbə sayı</label>
                    <input
                      type="number"
                      {...register("floor_count")}
                      style={
                        errors.floor_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      step="1"
                    />
                    {errors.floor_count && (
                      <p className={styles.textDanger}>
                        Mərtəbə sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="bedCount">
                      Çarpayı sayı <span className={styles.redStar}>*</span>
                    </label>
                    <input
                      type="number"
                      step="1"
                      {...register("bed_count", { required: true })}
                      style={
                        errors.bed_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.bed_count && (
                      <p className={styles.textDanger}>
                        Çarpayı sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="capacity">
                      Ümumi tutum <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="1"
                      {...register("total_capacity", { required: true })}
                      style={
                        errors.total_capacity
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.total_capacity && (
                      <p className={styles.textDanger}>
                        Ümumi tutumu daxil edin
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="repair">Təmir vəziyyəti</label>
                    <select
                      name="repair"
                      id="repair"
                      className={styles.tabe}
                      {...register("repair_status", { value: "1" })}
                    >
                      <option value="1">Əla</option>
                      <option value="2">Orta</option>
                      <option value="3">Zəif</option>
                      <option value="4">Təmirsiz</option>
                    </select>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="explotation">İstismar vəziyyəti</label>
                    <select
                      name="explotation"
                      id="explotation"
                      {...register("exploitation", { value: "1" })}
                    >
                      <option value="1">Istismara verilib</option>
                      <option value="0">Istismara verilməyib</option>
                    </select>
                  </div>
                  <div className={styles.selectRes1}>
                    <label htmlFor="field">Fəaliyyəti</label>
                    <select
                      name="field"
                      id="field"
                      {...register("activity", { value: "1" })}
                    >
                      <option value="1">Fəaliyyət göstərir</option>
                      <option value="2">Fəaliyyət göstərmir</option>
                      <option value="3">
                        Noyabr 2024-də fəaliyyətə hazır olacaq
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="supply">Təchizatı</label>
                    <select
                      name="supply"
                      id="supply"
                      className={styles.tabe}
                      {...register("supply", { value: "1" })}
                    >
                      <option value="1">Tam təchizatlı</option>
                      <option value="0">Təchizatsız</option>
                    </select>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Parkinq</h2>
                <div className={styles.secondRow}>
                  <div className={styles.region1}>
                    <label htmlFor="parkingCount">
                      Parkinq yerlərinin sayı{" "}
                      <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="1"
                      {...register("parking", { required: true })}
                      style={
                        errors.parking
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    />
                    {errors.parking && (
                      <p className={styles.textDanger}>
                        Parkinq yerlərinin sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="underParkingCapacity">
                      Yeraltı parkinq tutumu
                    </label>
                    <input
                      type="number"
                      step="1"
                      {...register("underground_parking_capacity")}
                    />
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="upperParkingCapacity">
                      Yerüstü parkinq tutumu
                    </label>
                    <input
                      type="number"
                      step="1"
                      {...register("parking_capacity")}
                    />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="scooter-charge">
                      Elektrik şarı stansiyası
                      <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_charge_station
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="scooter-charge-yes"
                          name="scooter-charge"
                          value="1"
                          {...register("have_charge_station", {
                            required: true,
                          })}
                        />
                        <label for="scooter-charge-yes" className={styles.p}>
                          Var
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="scooter-charge-no"
                          name="scooter-charge"
                          value="0"
                          {...register("have_charge_station")}
                        />
                        <label for="scooter-charge-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="scooter">Skuter üçün parkinq</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.scooter_charging_station
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="scooter-yes"
                          name="scooter"
                          value="1"
                          {...register("scooter_charging_station")}
                        />
                        <label for="scooter-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="scooter-no"
                          name="scooter"
                          value="0"
                          {...register("scooter_charging_station")}
                        />
                        <label for="scooter-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="veloparking">Veloparkinq</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.veloparking
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="veloparking-yes"
                          name="veloparking"
                          value="1"
                          {...register("veloparking")}
                        />
                        <label for="veloparking-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="veloparking-no"
                          name="veloparking"
                          value="0"
                          {...register("veloparking")}
                        />
                        <label for="veloparking-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>
                  Məhdud fiziki qabiliyyətli insanlar (MFQ) üçün yaşayış imkanı
                </h2>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="mfq_live" style={{ fontSize: "14px" }}>
                      MFQ üçün yaşayış imkanı{" "}
                      <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_live
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="mfq-live-yes"
                          name="mfq_live"
                          value="1"
                          {...register("mfq_live", { required: true })}
                        />
                        <label for="mfq-live-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="mfq-live-no"
                          name="mfq_live"
                          value="0"
                          {...register("mfq_live")}
                        />
                        <label for="mfq-live-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="pandus">MFQ Pandus</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_pandus
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="pandus-yes"
                          name="pandus"
                          value="1"
                          {...register("mfq_pandus")}
                        />
                        <label for="pandus-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="pandus-no"
                          name="pandus"
                          value="0"
                          {...register("mfq_pandus")}
                        />
                        <label for="pandus-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="elevator">MFQ Lift</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_elevator
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="elevator-yes"
                          name="elevator"
                          value="1"
                          {...register("mfq_elevator")}
                        />
                        <label for="elevator-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="elevator-no"
                          name="elevator"
                          value="0"
                          {...register("mfq_elevator")}
                        />
                        <label for="elevator-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="wc">MFQ WC</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_wc
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="wc-yes"
                          name="wc"
                          value="1"
                          {...register("mfq_wc")}
                        />
                        <label for="wc-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="wc-no"
                          name="wc"
                          value="0"
                          {...register("mfq_wc")}
                        />
                        <label for="wc-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>EKO davamlılıq</h2>
                <div className={styles.firstRow}>
                  <div className={`${styles.region1} ${styles.selectRes2}`}>
                    <label htmlFor="sustainability">
                      EKO davamlılıq (
                      {selectedOptions?.length + "/" + options?.length})
                    </label>
                    <Select
                      isMulti
                      name="colors"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedOptions}
                      onChange={handleChange}
                      closeMenuOnSelect={false}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "56px",
                          outlineColor: "black!important",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                          ...theme.colors,
                          primary25: "#2267CE50",
                          primary: "black",
                          secondary: "white",
                        },
                      })}
                    />
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Xidmətlər</h2>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="restourant">
                      Restoran <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_restaurant
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="restourant-yes"
                          name="restourant"
                          value="1"
                          {...register("have_restaurant", { required: true })}
                          onClick={() => setRestourantDis(false)}
                        />
                        <label for="restourant-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="restourant-no"
                          name="restourant"
                          value="0"
                          {...register("have_restaurant")}
                          onClick={() => setRestourantDis(true)}
                        />
                        <label for="restourant-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.doubleIn}>
                    <div>
                      <label htmlFor="restourantCount">Sayı</label>
                      <input
                        type="number"
                        step="1"
                        {...register("count_restaurant")}
                        disabled={restourantDis}
                      />
                    </div>
                    <div>
                      <label htmlFor="restourantCapacity">Ümumi tutum</label>
                      <input
                        type="number"
                        step="1"
                        {...register("capacity_restaurant")}
                        disabled={restourantDis}
                      />
                    </div>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="restourantNote">Qeyd/Təsvir</label>
                    <input
                      type="text"
                      {...register("note_restaurant")}
                      disabled={restourantDis}
                    />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="conferenceRoom">
                      Konfrans zalı <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_hall
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="conferenceRoom-yes"
                          name="conferenceRoom"
                          value="1"
                          {...register("have_hall", { required: true })}
                          onClick={() => setConfranceDis(false)}
                        />
                        <label for="conferenceRoom-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="conferenceRoom-no"
                          name="conferenceRoom"
                          value="0"
                          {...register("have_hall")}
                          onClick={() => setConfranceDis(true)}
                        />
                        <label for="conferenceRoom-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.doubleIn}>
                    <div>
                      <label htmlFor="hallCount">Zalların sayı</label>
                      <input
                        type="number"
                        step="1"
                        {...register("hall_count")}
                        disabled={confranceDis}
                      />
                    </div>
                    <div>
                      <label htmlFor="hallCapacity">Ümumi tutum</label>
                      <input
                        type="number"
                        step="1"
                        {...register("capacity_hall")}
                        disabled={confranceDis}
                      />
                    </div>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="hallNote">Qeyd/Təsvir</label>
                    <input
                      type="text"
                      {...register("note_hall")}
                      disabled={confranceDis}
                    />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="spa">
                      Spa mərkəzi <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_spa
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="spa-yes"
                          name="spa"
                          value="1"
                          {...register("have_spa", { required: true })}
                        />
                        <label for="spa-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="spa-no"
                          name="spa"
                          value="0"
                          {...register("have_spa")}
                        />
                        <label for="spa-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="hospital">Tibb məntəqəsi</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.medical_station
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="yes"
                          name="hospital"
                          value="1"
                          {...register("medical_station")}
                        />
                        <p>Var</p>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="no"
                          name="hospital"
                          value="0"
                          {...register("medical_station")}
                        />
                        <p>Yoxdur</p>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="trainer">
                      İdman zalı <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_trenajor
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="trainer-yes"
                          name="trainer"
                          value="1"
                          {...register("have_trenajor", { required: true })}
                        />
                        <label for="trainer-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="trainer-no"
                          name="trainer"
                          value="0"
                          {...register("have_trenajor")}
                        />
                        <label for="trainer-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="tennisCourt">Tennis kortu</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_tennis
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="tennisCourt-yes"
                          name="tennisCourt"
                          value="1"
                          {...register("have_tennis")}
                        />
                        <label for="tennisCourt-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="tennisCourt-no"
                          name="tennisCourt"
                          value="0"
                          {...register("have_tennis")}
                        />
                        <label for="tennisCourt-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="footballField">Futbol meydançası</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_football
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="footballField-yes"
                          name="footballField"
                          value="1"
                          {...register("have_football")}
                        />
                        <label for="footballField-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="footballField-no"
                          name="footballField"
                          value="0"
                          {...register("have_football")}
                        />
                        <label for="footballField-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="childrenCentre">Uşaq əyləncə mərkəzi</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_child_entertainment
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="childrenCentre-yes"
                          name="childrenCentre"
                          value="1"
                          {...register("have_child_entertainment")}
                        />
                        <label for="childrenCentre-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="childrenCentre-no"
                          name="childrenCentre"
                          value="0"
                          {...register("have_child_entertainment")}
                        />
                        <label for="childrenCentre-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="roomService">Otaq xidməti</label>
                    <select
                      name="roomService"
                      id="roomService"
                      {...register("room_service", { value: "1" })}
                    >
                      <option value="1">24 saat</option>
                      <option value="2">Saat məhdudiyyətli</option>
                      <option value="3">Yoxdur</option>
                    </select>
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="pool">
                      Hovuz <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_pool
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          id="pool-yes"
                          name="pool"
                          value="1"
                          {...register("have_pool", { required: true })}
                          onClick={() => setPoolDis(false)}
                        />
                        <label for="pool-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="pool-no"
                          name="pool"
                          value="0"
                          {...register("have_pool")}
                          onClick={() => setPoolDis(true)}
                        />
                        <label for="pool-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.doubleIn}>
                    <div>
                      <label htmlFor="openPool" style={{ fontSize: "13px" }}>
                        Açıq hovuz sayı
                      </label>
                      <input
                        type="number"
                        step="1"
                        {...register("pool_open_count")}
                        disabled={poolDis}
                      />
                    </div>
                    <div>
                      <label htmlFor="closePool" style={{ fontSize: "13px" }}>
                        Qapalı hovuz sayı
                      </label>
                      <input
                        type="number"
                        step="1"
                        {...register("pool_close_count")}
                        disabled={poolDis}
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={styles.textarea}>
                  <label htmlFor="additionalNotes">Əlavə qeydlər</label>
                  <textarea
                    name="additionalNotes"
                    id="additionalNotes"
                    {...register("note_place")}
                  ></textarea>
                </div>
              </div>
              <hr />

              <div className={styles.wrapper}>
                <h2>Yükləmələr</h2>
                <div className={styles.photos}>
                  <label
                    className={styles.file}
                    style={
                      frontImageError
                        ? { borderColor: "red", outline: "red" }
                        : null
                    }
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFrontImage(e)}
                    />
                    {frontImageLink ? (
                      <div
                        className={styles.imgDiv}
                        style={{ backgroundImage: `url(${frontImageLink})` }}
                      ></div>
                    ) : null}
                    <div>
                      <UploadIcon />
                      <p>Otelin öndən şəkli</p>
                    </div>
                    {frontImageLink ? (
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setFrontImageLink(null);
                          setFrontImage(null);
                        }}
                        className={styles.delete}
                      >
                        &#10005;
                      </div>
                    ) : null}
                  </label>
                  {roomsImageLink?.length > 0
                    ? roomsImageLink?.map((item, i) => (
                        <label className={styles.file} key={i}>
                          <img src={item} alt={i} />
                          <div
                            onClick={(e) => handleDelete(e, i)}
                            className={styles.delete}
                          >
                            &#10005;
                          </div>
                        </label>
                      ))
                    : null}

                  <label
                    className={styles.file}
                    style={
                      roomsImageError
                        ? { borderColor: "red", outline: "red" }
                        : null
                    }
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleRoomImages}
                      multiple
                    />
                    <div>
                      <UploadIcon />
                      <p>Otaqların şəkilləri</p>
                    </div>
                  </label>
                </div>
                {validation ? (
                  <div className={styles.validation}>
                    {validation?.map((item) => (
                      <p className={styles.textDanger}>{item[0]}</p>
                    ))}
                  </div>
                ) : null}
                <div className={styles.buttons}>
                  <button
                    type="button"
                    className={styles.grayButton}
                    onClick={() => window.location.reload()}
                  >
                    <p>Təmizlə</p>
                  </button>
                  <button className={styles.greenButton} onClick={() => {}}>
                    <p>Yadda saxla</p>
                    <RightArrow />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Registartion;
