import axios from "../index";

  const register = data => {
    return axios.post('/enterprise/store',data, {
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
    });
  }
  const updateRegister = (query,data) => {
    return axios.post(`enterprise/update/${query}`,data, {
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
    });
  }
  const deleteRegister = query => {
    return axios.get(`enterprise/delete/${query}`, {
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    });
  }

export default { register,updateRegister,deleteRegister}