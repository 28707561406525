import axios from "../index";

const districts = query => {
  return axios.get(`district/select?type=${query}`, {
    headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
  });
}
const environmentalSustainability = () => {
    return axios.get('environmental-sustainability/select', {
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    });
  }
  const category = () => {
    return axios.get('category/select', {
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    });
  }

  const subordination = () => {
    return axios.get('subordination/select', {
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    });
  }

export default { districts ,environmentalSustainability,category,subordination}