import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { RightArrow, TrashIcon, UploadIcon } from "../../icons";
import dropdowns from "../../api/dropdowns";
import { set, useForm } from "react-hook-form";
import form from "../../api/form";
import Select from "react-select";
import auth from "../../api/auth";
import { useParams } from "react-router-dom";

const Show = () => {
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [districts1, setDistricts1] = useState([]);
  const [environmentalSustainability, setEnvironmentalSustainability] =
    useState([]);
  const [category, setCategory] = useState([]);
  const [subordination, setSubordination] = useState([]);
  const [show, setShow] = useState([]);
  const [data, setData] = useState(null);
  const [frontImageError, setFrontImageError] = useState(null);
  const [frontImageLink, setFrontImageLink] = useState(null);
  const [frontImage, setFrontImage] = useState(null);

  const [roomsImageError, setRoomsImageError] = useState(null);
  const [roomsImageLink, setRoomsImageLink] = useState(null);
  const [roomsImage, setRoomsImage] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [edit, setEdit] = useState(true);
  const [oldRoomPhotos, setOldRoomPhotos] = useState(true);
  const [isAdmin, setIsAdmin] = useState(0);
  const [validation, setValidation] = useState(false);
  const [modal, setModal] = useState(false);
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  const [phonePrefix, setPhonePrefix] = useState("050");

  const handlePhone = (e) => {
    const inputValue = e.target.value;
    setPhoneError(false);
    const regex = /^[0-9]*$/;
    if (inputValue.length <= 7 && regex.test(inputValue)) {
      setPhone(inputValue);
    } else {
      if (inputValue.length > 7) {
        setPhone(inputValue.slice(0, 7));
      } else {
        setPhone(inputValue);
      }
    }
  };
  const handlePhonePrefix = (e) => {
    setPhonePrefix(e.target.value);
  };
  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };
  const handleShowChange = (key, value) => {
    setShow((prevShow) => ({
      ...prevShow,
      [key]: value,
    }));
  };
  const handleFrontImage = (e) => {
    setFrontImageError(null);
    setFrontImageLink(URL.createObjectURL(e.target.files[0]));
    setFrontImage(e.target.files[0]);
  };
  const handleRoomImages = (e) => {
    const addRoomsImageLink = (newImages) => {
      if (roomsImageLink !== null) {
        setRoomsImageLink([...roomsImageLink, ...newImages]);
      } else {
        setRoomsImageLink([...newImages]);
      }
    };
    const addRoomsImage = (newImages) => {
      if (roomsImage !== null) {
        setRoomsImage([...roomsImage, ...newImages]);
      } else {
        setRoomsImage([...newImages]);
      }
    };
    const files = e.target.files;
    const imagesArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setRoomsImageError(null);
    addRoomsImageLink(imagesArray);
    addRoomsImage(e.target.files);
  };
  const options = environmentalSustainability.map((option) => ({
    label: option.name,
    value: option.id,
  }));
  const handleDelete = (e, i) => {
    e.preventDefault();
    setRoomsImageLink((files) => {
      const updatedFiles = [...files];
      updatedFiles.splice(i, 1);
      return updatedFiles;
    });
    setRoomsImage((files) => {
      const updatedFiles = [...files];
      updatedFiles.splice(i, 1);
      return updatedFiles;
    });
  };
  const handleDeleteShow = (e, i) => {
    e.preventDefault();
    setOldRoomPhotos((files) => {
      const updatedFiles = [...files];
      updatedFiles.splice(i, 1);
      return updatedFiles;
    });
  };
  const handleDeleteRegistration = () => {
    setLoad(true);
    async function deletePost() {
      const data_ = await form
        .deleteRegister(id)
        .then((response) => {
          setLoad(false);
          console.log(response);
          window.location.replace("/registered");
        })
        .catch((error) => {
          setLoad(false);
          console.log(error);
          setModal(false);
        });
    }
    deletePost();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    async function fetchDistrics() {
      const { data } = await dropdowns.districts(1);
      setDistricts(data);
    }
    async function fetchDistrics1() {
      const { data } = await dropdowns.districts(2);
      setDistricts1(data);
    }
    async function fetchEnvironmentalSustainability() {
      const { data } = await dropdowns.environmentalSustainability();
      setEnvironmentalSustainability(data.data.environmental_sustainabilities);
    }
    async function fetchCategory() {
      const { data } = await dropdowns.category();
      setCategory(data);
    }
    async function fetchSubordination() {
      const { data } = await dropdowns.subordination();
      setSubordination(data);
    }
    async function fetchShow() {
      const { data } = await auth.showInfo(id);
      setShow(data.data.enterprise);
      setOldRoomPhotos(data.data.enterprise.room_photos);
      setFrontImage(data.data.enterprise.front_photo);
      if (data.data.enterprise.phone) {
        const phonePrefix = data.data.enterprise.phone.substring(0, 3);
        setPhonePrefix(phonePrefix);
        const phone = data.data.enterprise.phone.substring(3);
        setPhone(phone);
      }
    }
    async function admin() {
      const { data } = await auth.getSessionUser(id);
      setIsAdmin(data.data.user.is_admin);
    }
    if (sessionStorage?.getItem("token") !== null) {
      fetchDistrics();
      fetchDistrics1();
      fetchEnvironmentalSustainability();
      fetchCategory();
      fetchSubordination();
      fetchShow();
      admin();
    } else {
      window.location.replace("/");
    }
    setLoad(false);
  }, [id]);
  return (
    <section className={styles.section}>
      {load ? <div className={styles.overlay}></div> : null}
      {modal ? (
        <div className={styles.modal}>
          <div>
            <h2>Qeydiyyatı silməyinizə əminsiz?</h2>
            <div>
              <button
                className={styles.greenButton}
                onClick={() => setModal(false)}
              >
                Yox
              </button>
              <button
                className={styles.redButton}
                onClick={() => handleDeleteRegistration()}
              >
                Bəli
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {sessionStorage.getItem("token") ? (
        <>
          <div className={styles.registration}>
            <div className={styles.edit}>
              <button
                className={styles.redButton}
                onClick={() => setModal(true)}
              >
                <p>Sil</p>
                <TrashIcon />
              </button>
              {/* {isAdmin === 1 ? ( */}
              <button
                className={styles.greenButton}
                onClick={() => setEdit(false)}
              >
                <p>Redaktə et</p>
                <RightArrow />
              </button>
              {/* ) : null} */}
            </div>
            <hr />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setValidation(false);
                setLoad(true);
                async function registerPost() {
                  const roomIds = oldRoomPhotos.map((photo) =>
                    photo.id ? photo.id : photo
                  );
                  const newEnvironments = show?.environments?.map(
                    (env) => env.value
                  );
                  const roomItem = [...roomIds, ...roomsImage];
                  const datah = {
                    ...show,
                    front_photo: frontImage?.id ? frontImage?.id : frontImage,
                    room_photos_ids: roomIds,
                    room_photos: roomsImage,
                    environments: newEnvironments,
                    phone: phonePrefix + "" + phone,
                  };
                  const data_ = await form
                    .updateRegister(id, datah)
                    .then((response) => {
                      setLoad(false);
                      window.location.reload();
                    })
                    .catch((error) => {
                      setLoad(false);
                      const array = Object.keys(error.response.data.data).map(
                        (key) => error.response.data.data[key]
                      );
                      setValidation(array);
                      window.scrollTo({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: "smooth",
                      });
                    });
                }
                if (phone === "") {
                  setPhoneError(true);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }
                registerPost();
              }}
            >
              <div className={styles.wrapper}>
                <h2>Ərazi</h2>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="region">
                      Region <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.region
                          ? {
                              borderColor: "red",
                              outline: "red",
                            }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="baki"
                          name="region"
                          value="1"
                          checked={show?.region === 1 ? true : false}
                          onClick={() => handleShowChange("region", 1)}
                        />
                        <label for="baki" className={styles.p}>
                          Bakı
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="rayon"
                          name="region"
                          value="0"
                          checked={show?.region === 0 ? true : false}
                          onClick={() => handleShowChange("region", 0)}
                        />
                        <label for="rayon" className={styles.p}>
                          Region
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="region">Rayon</label>
                    <select
                      name="region"
                      id="region"
                      {...register("district_id", { value: "1" })}
                      onChange={(e) =>
                        handleShowChange("district_id", e.target.value)
                      }
                      disabled={edit}
                    >
                      {show?.region === 1 ? (
                        <>
                          {districts?.data?.districts?.map((item) => (
                            <option
                              key={item.id}
                              value={item.id}
                              selected={
                                show?.district_id === item.id ? true : null
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        <>
                          {districts1?.data?.districts?.map((item) => (
                            <option
                              key={item.id}
                              value={item.id}
                              selected={
                                show?.district_id === item.id ? true : null
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                  <div className={styles.selectRes}>
                    <label htmlFor="address">
                      Ünvan <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      {...register("address")}
                      onChange={(e) =>
                        handleShowChange("address", e.target.value)
                      }
                      style={
                        errors.address
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.address}
                      disabled={edit}
                      className={styles.inputRes}
                    />
                    {errors.address && (
                      <p className={styles.textDanger}>Ünvanı daxil edin</p>
                    )}
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="cordX">
                      Koordinat X <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="0.000000000001"
                      onChange={(e) =>
                        handleShowChange("coordinate_x", e.target.value)
                      }
                      style={
                        errors.coordinate_x
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.coordinate_x}
                      disabled={edit}
                    />
                    {errors.coordinate_x && (
                      <p className={styles.textDanger}>
                        X koordinatını daxil edin
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="cordY">
                      Koordinat Y <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="0.000000000001"
                      onChange={(e) =>
                        handleShowChange("coordinate_y", e.target.value)
                      }
                      style={
                        errors.coordinate_y
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.coordinate_y}
                      disabled={edit}
                    />
                    {errors.coordinate_y && (
                      <p className={styles.textDanger}>
                        Y koordinatını daxil edin
                      </p>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Kateqoriyalar</h2>
                <div className={styles.secondRow}>
                  <div className={styles.region1} style={{ width: 29 + "%" }}>
                    <label htmlFor="category">Kateqoriya</label>
                    <select
                      name="category"
                      id="category"
                      onChange={(e) =>
                        handleShowChange("category_id", e.target.value)
                      }
                      disabled={edit}
                    >
                      {category?.data?.categories?.map((item) => (
                        <option
                          value={item.id}
                          selected={show?.category_id === item.id ? true : null}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="star">Ulduz</label>
                    <select
                      name="star"
                      id="star"
                      onChange={(e) => handleShowChange("star", e.target.value)}
                      disabled={edit}
                    >
                      <option
                        value="0"
                        selected={show?.star === 0 ? true : null}
                      >
                        0
                      </option>
                      <option
                        value="1"
                        selected={show?.star === 1 ? true : null}
                      >
                        1
                      </option>
                      <option
                        value="2"
                        selected={show?.star === 2 ? true : null}
                      >
                        2
                      </option>
                      <option
                        value="3"
                        selected={show?.star === 3 ? true : null}
                      >
                        3
                      </option>
                      <option
                        value="4"
                        selected={show?.star === 4 ? true : null}
                      >
                        4
                      </option>
                      <option
                        value="5"
                        selected={show?.star === 5 ? true : null}
                      >
                        5
                      </option>
                    </select>
                  </div>
                  {/*  */}
                  <div>
                    <label htmlFor="structureName">
                      Müəssisənin adı <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      style={
                        errors.name
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      onChange={(e) => handleShowChange("name", e.target.value)}
                      value={show?.name}
                      disabled={edit}
                    />
                    {errors.name && (
                      <p className={styles.textDanger}>
                        Müəssisənin adını daxil edin
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="name_surname">
                      Ad, soyad <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      style={
                        errors.name_surname
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      onChange={(e) =>
                        handleShowChange("name_surname", e.target.value)
                      }
                      value={show?.name_surname}
                      disabled={edit}
                    />
                    {errors.name_surname && (
                      <p className={styles.textDanger}>Ad, soyadı daxil edin</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="phone">
                      Nömrə <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <div className={styles.numberInput}>
                      <select
                        name="prefix"
                        id="prefix"
                        value={phonePrefix === null ? "050" : phonePrefix}
                        onChange={(e) => handlePhonePrefix(e)}
                        disabled={edit}
                      >
                        <option value="050">050</option>
                        <option value="051">051</option>
                        <option value="010">010</option>
                        <option value="070">070</option>
                        <option value="077">077</option>
                        <option value="055">055</option>
                        <option value="090">090</option>
                        <option value="012">012</option>
                      </select>
                      <input
                        type="number"
                        step="1"
                        value={phone}
                        onChange={(e) => handlePhone(e)}
                        disabled={edit}
                        style={
                          phoneError
                            ? { borderColor: "red", outline: "red" }
                            : null
                        }
                      />
                    </div>
                    {phoneError && (
                      <p className={styles.textDanger}>Nömrəni daxil edin</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="subordination">Tabeçilik</label>
                    <select
                      name="subordination"
                      id="subordination"
                      className={styles.tabe}
                      onChange={(e) =>
                        handleShowChange("subordination_id", e.target.value)
                      }
                      disabled={edit}
                    >
                      {subordination?.data?.subordinations?.map((item) => (
                        <option
                          value={item.id}
                          selected={
                            show?.subordination_id === item.id ? true : null
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Müəssisənin sahə tutumu</h2>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="area">Ümumi sahə (m&sup2;)</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        handleShowChange("common_area", e.target.value)
                      }
                      style={
                        errors.common_area
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.common_area}
                      disabled={edit}
                    />
                    {errors.common_area && (
                      <p className={styles.textDanger}>
                        Ümumi sahəni daxil edin
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="buildingCount">
                      Bina sayı <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("building_count", e.target.value)
                      }
                      className={styles.inputRes}
                      style={
                        errors.building_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.building_count}
                      disabled={edit}
                    />
                    {errors.building_count && (
                      <p className={styles.textDanger}>
                        Bina sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="suitCount">
                      Otaq sayı <span className={styles.redStar}>*</span>
                    </label>
                    <input
                      className={styles.inputRes}
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("room_count", e.target.value)
                      }
                      style={
                        errors.room_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.room_count}
                      disabled={edit}
                    />
                    {errors.room_count && (
                      <p className={styles.textDanger}>
                        Otaq sayını daxil edin
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.fiveIn}>
                  <div>
                    <label htmlFor="oneFloor">1 otaqlı</label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) => handleShowChange("one", e.target.value)}
                      value={show?.one}
                      disabled={edit}
                    />
                  </div>
                  <div>
                    <label htmlFor="twoFloor">2 otaqlı</label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) => handleShowChange("two", e.target.value)}
                      value={show?.two}
                      disabled={edit}
                    />
                  </div>
                  <div>
                    <label htmlFor="threeFloor">3 otaqlı</label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("three", e.target.value)
                      }
                      value={show?.three}
                      disabled={edit}
                    />
                  </div>
                  <div>
                    <label htmlFor="fourFloor">4 otaqlı</label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) => handleShowChange("four", e.target.value)}
                      value={show?.four}
                      disabled={edit}
                    />
                  </div>
                  <div>
                    <label htmlFor="fiveFloor">5 otaqlı</label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) => handleShowChange("five", e.target.value)}
                      value={show?.five}
                      disabled={edit}
                    />
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="suitCount">Suit sayı</label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) => handleShowChange("suit", e.target.value)}
                      value={show?.suit}
                      disabled={edit}
                    />
                  </div>
                  <div>
                    <label htmlFor="presidentSuitCount">
                      Prezident suit sayı
                    </label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("president_suit", e.target.value)
                      }
                      value={show?.president_suit}
                      disabled={edit}
                    />
                  </div>
                  <div>
                    <label htmlFor="address">Kotec sayı</label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("kotec", e.target.value)
                      }
                      value={show?.kotec}
                      disabled={edit}
                    />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div className={styles.region1}>
                    <label htmlFor="floor">Mərtəbə sayı</label>
                    <input
                      type="number"
                      onChange={(e) =>
                        handleShowChange("floor_count", e.target.value)
                      }
                      style={
                        errors.floor_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      step="1"
                      value={show?.floor_count}
                      disabled={edit}
                    />
                    {errors.floor_count && (
                      <p className={styles.textDanger}>
                        Mərtəbə sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="bedCount">
                      Çarpayı sayı <span className={styles.redStar}>*</span>
                    </label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("bed_count", e.target.value)
                      }
                      style={
                        errors.bed_count
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.bed_count}
                      disabled={edit}
                    />
                    {errors.bed_count && (
                      <p className={styles.textDanger}>
                        Çarpayı sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="capacity">
                      Ümumi tutum <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("total_capacity", e.target.value)
                      }
                      style={
                        errors.total_capacity
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.total_capacity}
                      disabled={edit}
                    />
                    {errors.total_capacity && (
                      <p className={styles.textDanger}>
                        Ümumi tutumu daxil edin
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="repair">Təmir vəziyyəti</label>
                    <select
                      name="repair"
                      id="repair"
                      className={styles.tabe}
                      onChange={(e) =>
                        handleShowChange("repair_status", e.target.value)
                      }
                      disabled={edit}
                    >
                      <option
                        value="1"
                        selected={show?.repair_status === 1 ? true : null}
                      >
                        Əla
                      </option>
                      <option
                        value="2"
                        selected={show?.repair_status === 2 ? true : null}
                      >
                        Orta
                      </option>
                      <option
                        value="3"
                        selected={show?.repair_status === 3 ? true : null}
                      >
                        Zəif
                      </option>
                      <option
                        value="4"
                        selected={show?.repair_status === 4 ? true : null}
                      >
                        Təmirsiz
                      </option>
                    </select>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="explotation">İstismar vəziyyəti</label>
                    <select
                      name="explotation"
                      id="explotation"
                      onChange={(e) =>
                        handleShowChange("exploitation", e.target.value)
                      }
                      disabled={edit}
                    >
                      <option
                        value="1"
                        selected={show?.exploitation === 1 ? true : null}
                      >
                        Istismara verilib
                      </option>
                      <option
                        value="0"
                        selected={show?.exploitation === 0 ? true : null}
                      >
                        Istismara verilməyib
                      </option>
                    </select>
                  </div>
                  <div className={styles.selectRes1}>
                    <label htmlFor="field">Fəaliyyəti</label>
                    <select
                      name="field"
                      id="field"
                      onChange={(e) =>
                        handleShowChange("activity", e.target.value)
                      }
                      disabled={edit}
                    >
                      <option
                        value="1"
                        selected={show?.activity === 1 ? true : null}
                      >
                        Fəaliyyət göstərir
                      </option>
                      <option
                        value="2"
                        selected={show?.activity === 2 ? true : null}
                      >
                        Fəaliyyət göstərmir
                      </option>
                      <option
                        value="3"
                        selected={show?.activity === 3 ? true : null}
                      >
                        Noyabr 2024-də fəaliyyətə hazır olacaq
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles.secondRow}>
                  <div>
                    <label htmlFor="supply">Təchizatı</label>
                    <select
                      name="supply"
                      id="supply"
                      className={styles.tabe}
                      onChange={(e) =>
                        handleShowChange("supply", e.target.value)
                      }
                      disabled={edit}
                    >
                      <option
                        value="1"
                        selected={show?.supply === 1 ? true : null}
                      >
                        Tam təchizatlı
                      </option>
                      <option
                        value="0"
                        selected={show?.supply === 0 ? true : null}
                      >
                        Təchizatsız
                      </option>
                    </select>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Parkinq</h2>
                <div className={styles.secondRow}>
                  <div className={styles.region1}>
                    <label htmlFor="parkingCount">
                      Parkinq yerlərinin sayı{" "}
                      <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("parking", e.target.value)
                      }
                      style={
                        errors.parking
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      value={show?.parking}
                      disabled={edit}
                    />
                    {errors.parking && (
                      <p className={styles.textDanger}>
                        Parkinq yerlərinin sayını daxil edin
                      </p>
                    )}
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="underParkingCapacity">
                      Yeraltı parkinq tutumu
                    </label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange(
                          "underground_parking_capacity",
                          e.target.value
                        )
                      }
                      value={show?.underground_parking_capacity}
                      disabled={edit}
                    />
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="upperParkingCapacity">
                      Yerüstü parkinq tutumu
                    </label>
                    <input
                      type="number"
                      step="1"
                      onChange={(e) =>
                        handleShowChange("parking_capacity", e.target.value)
                      }
                      value={show?.parking_capacity}
                      disabled={edit}
                    />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="scooter">
                      Elektrik şarı stansiyası
                      <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_charge_station
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="scooter-charge-yes"
                          name="scooter-charge"
                          value="1"
                          onClick={() =>
                            handleShowChange("have_charge_station", 1)
                          }
                          checked={
                            show?.have_charge_station === 1 ? true : false
                          }
                        />
                        <label for="scooter-charge-yes" className={styles.p}>
                          Var
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="scooter-charge-no"
                          name="scooter-charge"
                          value="0"
                          onClick={() =>
                            handleShowChange("have_charge_station", 0)
                          }
                          checked={
                            show?.have_charge_station === 0 ? true : false
                          }
                        />
                        <label for="scooter-charge-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="scooter">Skuter üçün parkinq</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.scooter_charging_station
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="scooter-yes"
                          name="scooter"
                          value="1"
                          onClick={() =>
                            handleShowChange("scooter_charging_station", 1)
                          }
                          checked={
                            show?.scooter_charging_station === 1 ? true : false
                          }
                        />
                        <label for="scooter-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="scooter-no"
                          name="scooter"
                          value="0"
                          {...register("scooter_charging_station")}
                          onClick={() =>
                            handleShowChange("scooter_charging_station", 0)
                          }
                          checked={
                            show?.scooter_charging_station === 0 ? true : false
                          }
                        />
                        <label for="scooter-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="veloparking">Veloparkinq</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.veloparking
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="veloparking-yes"
                          name="veloparking"
                          value="1"
                          onClick={() => handleShowChange("veloparking", 1)}
                          checked={show?.veloparking === 1 ? true : false}
                        />
                        <label for="veloparking-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="veloparking-no"
                          name="veloparking"
                          value="0"
                          onClick={() => handleShowChange("veloparking", 0)}
                          checked={show?.veloparking === 0 ? true : false}
                        />
                        <label for="veloparking-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>
                  Məhdud fiziki qabiliyyətli insanlar (MFQ) üçün yaşayış imkanı
                </h2>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="mfq_live" style={{ fontSize: "14px" }}>
                      MFQ üçün yaşayış imkanı{" "}
                      <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_live
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="mfq-live-yes"
                          name="mfq_live"
                          value="1"
                          onClick={() => handleShowChange("mfq_live", 1)}
                          checked={show?.mfq_live === 1 ? true : false}
                        />
                        <label for="mfq-live-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="mfq-live-no"
                          name="mfq_live"
                          value="0"
                          onClick={() => handleShowChange("mfq_live", 0)}
                          checked={show?.mfq_live === 0 ? true : false}
                        />
                        <label for="mfq-live-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="pandus">MFQ Pandus</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_pandus
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="pandus-yes"
                          name="pandus"
                          value="1"
                          onClick={() => handleShowChange("mfq_pandus", 1)}
                          checked={show?.mfq_pandus === 1 ? true : false}
                        />
                        <label for="pandus-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="pandus-no"
                          name="pandus"
                          value="0"
                          onClick={() => handleShowChange("mfq_pandus", 0)}
                          checked={show?.mfq_pandus === 0 ? true : false}
                        />
                        <label for="pandus-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="elevator">MFQ Lift</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_elevator
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="elevator-yes"
                          name="elevator"
                          value="1"
                          onClick={() => handleShowChange("mfq_elevator", 1)}
                          checked={show?.mfq_elevator === 1 ? true : false}
                        />
                        <label for="elevator-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="elevator-no"
                          name="elevator"
                          value="0"
                          onClick={() => handleShowChange("mfq_elevator", 0)}
                          checked={show?.mfq_elevator === 0 ? true : false}
                        />
                        <label for="elevator-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="wc">MFQ WC</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.mfq_wc
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="wc-yes"
                          name="wc"
                          value="1"
                          onClick={() => handleShowChange("mfq_wc", 1)}
                          checked={show?.mfq_wc === 1 ? true : false}
                        />
                        <label for="wc-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="wc-no"
                          name="wc"
                          value="0"
                          onClick={() => handleShowChange("mfq_wc", 0)}
                          checked={show?.mfq_wc === 0 ? true : false}
                        />
                        <label for="wc-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>EKO davamlılıq</h2>
                <div className={styles.firstRow}>
                  <div className={`${styles.region1} ${styles.selectRes2}`}>
                    <label htmlFor="sustainability">
                      EKO davamlılıq (
                      {show?.environments?.length + "/" + options?.length})
                    </label>
                    <Select
                      isMulti
                      name="colors"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={show?.environments}
                      onChange={(e) => handleShowChange("environments", e)}
                      closeMenuOnSelect={false}
                      isDisabled={edit}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "56px",
                          outlineColor: "black!important",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                          ...theme.colors,
                          primary25: "#2267CE50",
                          primary: "black",
                          secondary: "white",
                        },
                      })}
                    />
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <hr />
              <div className={styles.wrapper}>
                <h2>Xidmətlər</h2>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="restourant">
                      Restoran <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_restaurant
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="restourant-yes"
                          name="restourant"
                          value="1"
                          onClick={() => handleShowChange("have_restaurant", 1)}
                          checked={show?.have_restaurant === 1 ? true : false}
                        />
                        <label for="restourant-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="restourant-no"
                          name="restourant"
                          value="0"
                          onClick={() => handleShowChange("have_restaurant", 0)}
                          checked={show?.have_restaurant === 0 ? true : false}
                        />
                        <label for="restourant-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.doubleIn}>
                    <div>
                      <label htmlFor="restourantCount">Sayı</label>
                      <input
                        type="number"
                        step="1"
                        onChange={(e) =>
                          handleShowChange("count_restaurant", e.target.value)
                        }
                        value={show?.count_restaurant}
                        disabled={edit}
                      />
                    </div>
                    <div>
                      <label htmlFor="restourantCapacity">Ümumi tutum</label>
                      <input
                        type="number"
                        step="1"
                        onChange={(e) =>
                          handleShowChange(
                            "capacity_restaurant",
                            e.target.value
                          )
                        }
                        value={show?.capacity_restaurant}
                        disabled={edit}
                      />
                    </div>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="restourantNote">Qeyd/Təsvir</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        handleShowChange("note_restaurant", e.target.value)
                      }
                      value={show?.note_restaurant}
                      disabled={edit}
                    />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="conferenceRoom">
                      Konfrans zalı <span className={styles.redStar}>*</span>{" "}
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_hall
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="conferenceRoom-yes"
                          name="conferenceRoom"
                          value="1"
                          onClick={() => handleShowChange("have_hall", 1)}
                          checked={show?.have_hall === 1 ? true : false}
                        />
                        <label for="conferenceRoom-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="conferenceRoom-no"
                          name="conferenceRoom"
                          value="0"
                          onClick={() => handleShowChange("have_hall", 0)}
                          checked={show?.have_hall === 0 ? true : false}
                        />
                        <label for="conferenceRoom-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.doubleIn}>
                    <div>
                      <label htmlFor="hallCount">Zalların sayı</label>
                      <input
                        type="number"
                        step="1"
                        onChange={(e) =>
                          handleShowChange("hall_count", e.target.value)
                        }
                        value={show?.hall_count}
                        disabled={edit}
                      />
                    </div>
                    <div>
                      <label htmlFor="hallCapacity">Ümumi tutum</label>
                      <input
                        type="number"
                        step="1"
                        onChange={(e) =>
                          handleShowChange("capacity_hall", e.target.value)
                        }
                        value={show?.capacity_hall}
                        disabled={edit}
                      />
                    </div>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="hallNote">Qeyd/Təsvir</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        handleShowChange("note_hall", e.target.value)
                      }
                      value={show?.note_hall}
                      disabled={edit}
                    />
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="spa">
                      Spa mərkəzi <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_spa
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="spa-yes"
                          name="spa"
                          value="1"
                          onClick={() => handleShowChange("have_spa", 1)}
                          checked={show?.have_spa === 1 ? true : false}
                        />
                        <label for="spa-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="spa-no"
                          name="spa"
                          value="0"
                          onClick={() => handleShowChange("have_spa", 0)}
                          checked={show?.have_spa === 0 ? true : false}
                        />
                        <label for="spa-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="hospital">Tibb məntəqəsi</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.medical_station
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="yes"
                          name="hospital"
                          value="1"
                          onClick={() => handleShowChange("medical_station", 1)}
                          checked={show?.medical_station === 1 ? true : false}
                        />
                        <p>Var</p>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="no"
                          name="hospital"
                          value="0"
                          onClick={() => handleShowChange("medical_station", 0)}
                          checked={show?.medical_station === 0 ? true : false}
                        />
                        <p>Yoxdur</p>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="trainer">
                      İdman zalı <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_trenajor
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="trainer-yes"
                          name="trainer"
                          value="1"
                          onClick={() => handleShowChange("have_trenajor", 1)}
                          checked={show?.have_trenajor === 1 ? true : false}
                        />
                        <label for="trainer-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="trainer-no"
                          name="trainer"
                          value="0"
                          onClick={() => handleShowChange("have_trenajor", 0)}
                          checked={show?.have_trenajor === 0 ? true : false}
                        />
                        <label for="trainer-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="tennisCourt">Tennis kortu</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_tennis
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="tennisCourt-yes"
                          name="tennisCourt"
                          value="1"
                          onClick={() => handleShowChange("have_tennis", 1)}
                          checked={show?.have_tennis === 1 ? true : false}
                        />
                        <label for="tennisCourt-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="tennisCourt-no"
                          name="tennisCourt"
                          value="0"
                          onClick={() => handleShowChange("have_tennis", 0)}
                          checked={show?.have_tennis === 0 ? true : false}
                        />
                        <label for="tennisCourt-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="footballField">Futbol meydançası</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_football
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="footballField-yes"
                          name="footballField"
                          value="1"
                          onClick={() => handleShowChange("have_football", 1)}
                          checked={show?.have_football === 1 ? true : false}
                        />
                        <label for="footballField-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="footballField-no"
                          name="footballField"
                          value="0"
                          onClick={() => handleShowChange("have_football", 0)}
                          checked={show?.have_football === 0 ? true : false}
                        />
                        <label for="footballField-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label htmlFor="childrenCentre">Uşaq əyləncə mərkəzi</label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_child_entertainment
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="childrenCentre-yes"
                          name="childrenCentre"
                          value="1"
                          onClick={() =>
                            handleShowChange("have_child_entertainment", 1)
                          }
                          checked={
                            show?.have_child_entertainment === 1 ? true : false
                          }
                        />
                        <label for="childrenCentre-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="childrenCentre-no"
                          name="childrenCentre"
                          value="0"
                          onClick={() =>
                            handleShowChange("have_child_entertainment", 0)
                          }
                          checked={
                            show?.have_child_entertainment === 0 ? true : false
                          }
                        />
                        <label for="childrenCentre-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.region1}>
                    <label htmlFor="roomService">Otaq xidməti</label>
                    <select
                      name="roomService"
                      id="roomService"
                      onChange={(e) =>
                        handleShowChange("room_service", e.target.value)
                      }
                      disabled={edit}
                      value={show?.room_service ? show?.room_service : "1"}
                    >
                      <option
                        value="1"
                        // selected={show?.room_service === 1 ? true : null}
                      >
                        24 saat
                      </option>
                      <option
                        value="2"
                        // selected={show?.room_service === 2 ? true : null}
                      >
                        Saat məhdudiyyətli
                      </option>
                      <option
                        value="3"
                        // selected={show?.room_service === 3 ? true : null}
                      >
                        Yoxdur
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div>
                    <label htmlFor="pool">
                      Hovuz <span className={styles.redStar}>*</span>
                    </label>
                    <fieldset
                      className={styles.region}
                      style={
                        errors.have_pool
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                      disabled={edit}
                    >
                      <div>
                        <input
                          type="radio"
                          id="pool-yes"
                          name="pool"
                          value="1"
                          onClick={() => handleShowChange("have_pool", 1)}
                          checked={show?.have_pool === 1 ? true : false}
                        />
                        <label for="pool-yes" className={styles.p}>
                          Var
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="pool-no"
                          name="pool"
                          value="0"
                          onClick={() => handleShowChange("have_pool", 0)}
                          checked={show?.have_pool === 0 ? true : false}
                        />
                        <label for="pool-no" className={styles.p}>
                          Yoxdur
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className={styles.doubleIn}>
                    <div>
                      <label htmlFor="openPool" style={{ fontSize: "13px" }}>
                        Açıq hovuz sayı
                      </label>
                      <input
                        type="number"
                        step="1"
                        onChange={(e) =>
                          handleShowChange("pool_open_count", e.target.value)
                        }
                        value={show?.pool_open_count}
                        disabled={edit}
                      />
                    </div>
                    <div>
                      <label htmlFor="closePool" style={{ fontSize: "13px" }}>
                        Qapalı hovuz sayı
                      </label>
                      <input
                        type="number"
                        step="1"
                        onChange={(e) =>
                          handleShowChange("pool_close_count", e.target.value)
                        }
                        value={show?.pool_close_count}
                        disabled={edit}
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={styles.textarea}>
                  <label htmlFor="additionalNotes">Əlavə qeydlər</label>
                  <textarea
                    name="additionalNotes"
                    id="additionalNotes"
                    onChange={(e) =>
                      handleShowChange("note_place", e.target.value)
                    }
                    value={show?.note_place}
                    disabled={edit}
                  ></textarea>
                </div>
              </div>
              <hr />

              <div className={styles.wrapper}>
                <h2>Yükləmələr</h2>
                <div className={styles.photos}>
                  <label
                    className={styles.file}
                    style={
                      frontImageError
                        ? { borderColor: "red", outline: "red" }
                        : null
                    }
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFrontImage(e)}
                      disabled={edit}
                    />
                    {frontImage || frontImageLink ? (
                      <div
                        className={styles.imgDiv}
                        style={{
                          backgroundImage: frontImage?.path
                            ? `url(https://api-hotels.cop29.az/${frontImage?.path})`
                            : `url(${frontImageLink})`,
                        }}
                      ></div>
                    ) : null}
                    <div>
                      <UploadIcon />
                      <p>Otelin öndən şəkli</p>
                    </div>
                    {frontImage || frontImageLink ? (
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setFrontImageLink(null);
                          setFrontImage(null);
                        }}
                        className={styles.delete}
                        style={{ display: edit ? "none" : "block" }}
                      >
                        &#10005;
                      </div>
                    ) : null}
                  </label>
                  {oldRoomPhotos?.length > 0
                    ? oldRoomPhotos?.map((item, i) => (
                        <label className={styles.file} key={i}>
                          <img
                            src={"https://api-hotels.cop29.az/" + item.path}
                            alt={i}
                          />
                          <div
                            onClick={(e) => handleDeleteShow(e, i)}
                            className={styles.delete}
                            style={{ display: edit ? "none" : "block" }}
                          >
                            &#10005;
                          </div>
                        </label>
                      ))
                    : null}
                  {roomsImageLink?.length > 0
                    ? roomsImageLink?.map((item, i) => (
                        <label className={styles.file} key={i}>
                          <img src={item} alt={i} />
                          <div
                            onClick={(e) => handleDelete(e, i)}
                            className={styles.delete}
                            style={{ display: edit ? "none" : "block" }}
                          >
                            &#10005;
                          </div>
                        </label>
                      ))
                    : null}

                  {!edit ? (
                    <label
                      className={styles.file}
                      style={
                        roomsImageError
                          ? { borderColor: "red", outline: "red" }
                          : null
                      }
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleRoomImages}
                        multiple
                      />
                      <div>
                        <UploadIcon />
                        <p>Otaqların şəkilləri</p>
                      </div>
                    </label>
                  ) : null}

                  {/* <div className={styles.photos}>
                    {show?.files?.map((item, i) => (
                      <label className={styles.file} key={i}>
                        <img
                          src={"https://api-hotels.cop29.az/" + item.path}
                          alt={i}
                        />
                      </label>
                    ))}
                  </div> */}
                </div>
                {validation ? (
                  <div className={styles.validation}>
                    {validation?.map((item) => (
                      <p className={styles.textDanger}>{item[0]}</p>
                    ))}
                  </div>
                ) : null}
                {/* {validation ? (
                  <div className={styles.validation}>
                    <p className={styles.textDanger}>{validation}</p>
                  </div>
                ) : null} */}
                {!edit ? (
                  <div className={styles.buttons}>
                    <button className={styles.greenButton} onClick={() => {}}>
                      <p>Yadda saxla</p>
                      <RightArrow />
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Show;
